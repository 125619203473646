import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function usePaymentList() {
  // Use toast
  const toast = useToast()

  const refPaymentListTable = ref(null)
  
  // Table Handlers
  const tableColumns = [
    { key: 'createdDate', sortable: true },
    { key: 'paymentIntent', sortable: true },
    { key: 'customer', sortable: true },
    { key: 'response', sortable: true },
  ]
  const perPage = ref(10)
  const totalPayments = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const userFilter = ref(null)
  
  const dataMeta = computed(() => {
    const localItemsCount = refPaymentListTable.value ? refPaymentListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalPayments.value,
    }
  })
  
  const refetchData = () => {
    refPaymentListTable.value.refresh()
  }
  
  watch([currentPage, perPage, searchQuery, userFilter], () => {
    refetchData()
  })
  
  const fetchPayment = (ctx, callback) => {
    store
      .dispatch('paymentManagement/fetchPayments', {
        Search: searchQuery.value,
        PageSize: perPage.value,
        PageIndex: currentPage.value,
        sortBy: sortBy.value,
        isSortDirDesc: isSortDirDesc.value,
        userId: userFilter.value,
      })
      .then(response => {
        const { data, count } = response.result;
        
        data.forEach(value => {
          if (value.paymentSuccessJson !== '' && value.paymentSuccessJson !== null) {
            value.paymentSuccessObject = JSON.parse(value.paymentSuccessJson);
          }
        })
        callback(data)
        totalPayments.value = count
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching orders list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  
  return {
    fetchPayment,
    tableColumns,
    perPage,
    currentPage,
    totalPayments,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refPaymentListTable,

    refetchData,
  
    // Extra Filters
    userFilter,
  }
}
